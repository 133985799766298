<template>
  <div>
    <p class="not-found">
      <span class="number">404</span>Oups! Seems like we've hit a rock...
    </p>
  </div>
</template>
<style scoped>
.not-found {
  margin-top: 2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
}
.number {
  font-size: 10rem;
}
</style>
